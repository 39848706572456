export const ROUTE_PATH = {
  HOME: '/',
  ABOUT_US: '/about-us',
  SERVICES: '/managed-services',
  CAREER: '/career',
  JOB_DETAIL: '/career/:id',
  EXPLORE_NEWS: '/explore-news',
  PORTFOLIO: '/Portfolio',
  NEW_DETAIL: '/explore-news/:id',
  CONTACT_US: '/contact-us',
  EXPERTISE: '/expertise',
  ON_DEMAND_SOFTWARE_DEVELOPMENT: '/on-demand-software-development',
  REMOTE_SOFTWARE: '/remote-software',
  RD_SOLUTION: '/r&d-solutions',
  GAME_DEVELOPMENT: '/game-development',
  DETAIL_PORTFOLIO: '/detail-portfolio',
  TECH_BLOGS: '/tech-blogs',
  TECH_BLOGS_DETAIL: '/tech-blogs-detail',

  LOGIN: '/admin/login',
  BLOG_MANAGEMENT: '/admin/blog'
};
