import styled from 'styled-components';

export const BlogDetailWrapper = styled.div`
  .bg-top {
    height: 100px;
    padding: 80px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .img-none {
    img,
    video {
      display: none;
    }
  }

  .ant-input-affix-wrapper {
    border-width: 0;
  }

  .ant-input-affix-wrapper:hover {
    border-inline-end-width: 0;
  }

  .button-custom {
    height: 34px;
    color: #fff;
    background: linear-gradient(90deg, #ff5400 0%, #ff8a00 100%);
  }

  @media screen and (min-width: 768px) {
    .bg-top {
      height: 400px;
    }
  }

  .stack-tag {
    font-weight: 700;
    line-height: 37.5px;
    color: #ff5400;
  }
`;
