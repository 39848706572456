import React, { useEffect } from 'react';
import { Routes } from './routes/routes';
import WebFont from 'webfontloader';
import { Provider } from 'react-redux';
import store from './store';
import { Toaster } from 'sonner';

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    });
  }, []);

  return (
    <Provider store={store}>
      <Toaster position='top-right' richColors />
      <Routes />
    </Provider>
  );
}

export default App;
